import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import Heading from 'libs/heading';
import { ContentToRichText, variableIsString } from 'libs/content';

const Wrapper = styled.div`
	@media print {
		margin: 20px 0 10px;
	}
`;

const Title = styled(Heading)`
	text-align: center;
	margin-bottom: 20px;
	@media print {
		text-align: left;
	}
`;

const Box = styled.div`
	padding: 35px;
	background: white;
	border: 1px solid ${p => p.theme.colors.grey300};
	@media print {
		background: ${p => p.theme.colors.grey200};
		padding: 15px;
	}
	${p =>
		p.theme.media.smallOnly(css`
			padding: 20px;
			background: #fdfdfd;
		`)}
	p {
		page-break-inside: avoid;
		font-size: 19px;
		position: relative;
		padding-left: 24px;
		margin-bottom: 12px;
		${p =>
			p.theme.media.smallOnly(css`
				font-size: 17px;
				word-break: break-word;
			`)}
		@media print {
			font-size: 16px;
			margin-bottom: 10px;
			padding: 0 0 0 18px;
		}
		&:last-of-type {
			margin: 0;
		}
		&::before {
			content: '';
			display: inline-block;
			border-radius: 6px;
			height: 6px;
			width: 6px;
			position: absolute;
			left: 0;
			top: 12px;
			background-color: ${p => p.theme.colors.grey900};
		}
	}
`;

export default function ResultBox({ title, headinglevel = 'h2', content }) {
	return (
		<Wrapper>
			{title && <Title level={headinglevel}>{title}</Title>}
			{content?.length > 0 && (
				<Box>
					{content.map((text, index) => {
						return (
							<Fragment key={index}>
								{(text?.raw && (
									<ContentToRichText data={text} />
								)) ||
									(text && variableIsString(text) && (
										<p>{text}</p>
									)) ||
									null}
							</Fragment>
						);
					})}
				</Box>
			)}
		</Wrapper>
	);
}
