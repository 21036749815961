import React from 'react';
import styled from 'styled-components';

import { usePopup } from 'context/PopupProvider';
import Button from 'components/forms/Button';

const Wrapper = styled.div`
	text-align: ${p => (p.$centered === 'true' ? 'center' : 'left')};
	width: 100%;
	button,
	a {
		margin: ${p =>
			`0 ${p.theme.spacing.desktop.xsmall} ${p.theme.spacing.desktop.xsmall} 0`};
	}
`;

export default function PopupButtons({
	centered = 'false',
	style,
	showContact = true,
	showTest = true,
	contactButtonText = 'Ta kontakt',
}) {
	const { activePopup, setActivePopup } = usePopup();

	return (
		<Wrapper $centered={centered} style={style}>
			{showContact && (
				<Button
					title={contactButtonText}
					aria-label="Klikk for å åpne popup-vindu med kontaktskjema"
					aria-haspopup={true}
					aria-controls="modal-form-contact"
					aria-expanded={activePopup === 'modal-form-contact'}
					tracking={false}
					onClick={() => {
						setActivePopup('modal-form-contact');
					}}>
					{contactButtonText}
				</Button>
			)}

			{showTest && (
				<Button
					title="Ta vår kontrolltest"
					aria-label="Klikk for å åpne popup-vindu med kontrolltest"
					aria-haspopup={true}
					aria-controls="modal-form-test"
					aria-expanded={activePopup === 'modal-form-test'}
					tracking={false}
					outlined={showContact && showTest ? 'true' : 'false'}
					onClick={() => {
						setActivePopup('modal-form-test');
					}}>
					Ta vår kontrolltest
				</Button>
			)}
		</Wrapper>
	);
}
