import React from 'react';
import styled, { css } from 'styled-components';

import Heading from 'libs/heading';
import Button from 'components/forms/Button';

const Cta = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	${p =>
		p.theme.media.smallOnly(css`
			text-align: center;
			flex-direction: column-reverse;
		`)}
	p {
		font-size: 19px;
		&:last-of-type {
			margin: 0;
		}
	}
`;

const TextWrapper = styled.div`
	flex: 1;
	${p =>
		p.theme.media.medium(css`
			padding-right: 40px;
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			margin-top: 20px;
			flex: 100%;
		`)}
`;

const Title = styled(Heading)`
	margin-bottom: 20px;
`;

const ImageWrapper = styled.div`
	${p =>
		p.theme.media.smallOnly(css`
			width: 100%;
		`)}
	img {
		width: 250px;
	}
`;

export default function CallToAction({
	title = 'La NTE hjelpe deg med anbefalingene',
	headinglevel = 'h2',
	buttonText = 'Få et uforpliktende tilbud',
	buttonUrl,
	image,
	imageAltText,
	...props
}) {
	return (
		<Cta>
			<TextWrapper>
				{title && <Title level={headinglevel}>{title}</Title>}
				{buttonText && (
					<Button title={buttonText} {...props}>
						{buttonText}
					</Button>
				)}
			</TextWrapper>

			{image && (
				<ImageWrapper>
					<img src={image} alt={imageAltText ? imageAltText : ''} />
				</ImageWrapper>
			)}
		</Cta>
	);
}
